@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

body {
  background-color: #0b0b17;
}

button {
  font-family: 'Work Sans', sans-serif;
}

.icon {
  width: 40px;
  margin: 30px auto;
}

.App {
  text-align: center;
}

button.iniciarEvento,
button.encerrarEvento {
  height: 30px;
  border-radius: 10px;
  padding: 0 20px;
  margin: 0 5px;
  border: 1px solid #777777;
  cursor: pointer;
}

.iniciarEvento:hover {
  background-color: #7fffdf !important;
  color: rgb(44, 41, 51);
}

.encerrarEvento:hover {
  background-color: #e24242 !important;
  color: #fff;
}

div.basePost {
  width: fit-content;
  margin: auto;
  align-items: flex-end;
  display: flex;
  position: relative;
}

div.imgPost {
  height: 100vh;
  /* width: 1000px; */
  aspect-ratio: 9/16;
  margin: auto;
  overflow: hidden;
}

div.imgPost img {
  position: relative;
  width: 100%;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  /* -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0))); */
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}

.fadeout {
  opacity: 1;
  animation: fadeout 500ms linear;
}

@keyframes fadeout {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

img.fadein {
  opacity: 1;
  animation: fadein 600ms linear;
}

div.fadein {
  opacity: 1;
  animation: fadein 1s linear;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeinPubli {
  0% {
    opacity: 0
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
}

div.aniBg div.linearFade {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: auto;
}

div.linearFade {
  position: sticky;
  bottom: 9vh;
  height: 90vh;
  background: linear-gradient(to bottom, transparent 50%, #0b0b17 100%);
}

div.linearTopFade {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to top, transparent 75%, #110b1ae0 95%);
}

div.basePost .legenda {
  position: absolute;
  bottom: 230px;
  animation: fadein 2s ease;
  margin-left: 40px;
  max-width: 340px;
  text-align: left;
  align-self: baseline;
}

div.basePost .legenda p {
  color: #fff;
  font-weight: 300;
  line-height: 1.4em;
  font-size: 15pt;
  margin-bottom: 0;
  margin-left: 15px;
}

div.basePost .legenda p::after {
  content: '';
  background-image: url(../src/images/talkBalloon.svg);
  background-repeat: no-repeat;
  bottom: -5px;
  left: -15px;
  position: relative;
  display: block;
  min-width: 100px;
  animation: fadein 3s ease;
  height: 35px;
}

div.infoPost {
  display: grid;
  grid-template-columns: 70% 20%;
}

div.infoPost .displayUser {
  position: absolute;
  bottom: 80px;
  width: 70%;
  margin-left: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 70%;
}

.Home div.infoPost .displayUser {
  width: 100%;
}

div.avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: double 4px transparent;
  background: linear-gradient(-45deg, #15ff20, #e73c7e, #23a6d5, #23d5ab), radial-gradient(circle at top left, #ff21e1, #ff1e62, #8a31ff, #23d5ab);
  background-origin: border-box;
  background-size: 400% 400%;
  background-clip: content-box, border-box;
  animation: gradient 2s ease infinite;
  aspect-ratio: 1 / 1;
  min-width: 100px;
  max-width: 300px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

div.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

div.infoPost div.texts {
  text-align: left;
  color: aliceblue;
  margin-left: 12px;
  max-width: 50%;
  font-size: 100%;
}

div.name h2 {
  margin-bottom: 5px;
  margin-top: 0;
}

div.infoPost div.texts p {
  margin: 2px 0;
  color: #c9c9c9;
}

div.infoPost div.texts .instaArroba {
  font-family: 'Work Sans', sans-serif;
  font-weight: initial;
  margin-top: 3px;
  font-size: 9pt;
  text-align: center;
  opacity: .80;
}

div#PubliForm div.infoPost div.texts {
  margin: 0 auto;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
}

div#PubliForm div.infoPost div.texts h2 {
  font-size: 14pt;
  font-weight: lighter;
  margin-bottom: 0;
  text-shadow: 0px 0px 10px #00000090;
}

div#PubliForm div.avatar {
  border: double 3px transparent;
  background: #ffffff20;
  box-shadow: 0px 0px 10px #00000050;
}

div.infoPost div.texts .instaArroba svg {
  width: 15px;

  padding-right: 5px;
  vertical-align: middle;
}

div#PubliForm div.infoPost div.tagQr {
  position: initial;
  margin-top: 10px;
}

div#PubliForm div.infoPost div.tagQr .labelQr p {
  font-size: 7pt;
}

div#PubliForm div.infoPost div.tagQr img {
  border-radius: 5px;
}

div#PubliForm div.infoPost div.texto {
  color: #fff;
  font-size: 12pt;
  font-family: 'Inter', sans-serif;
  font-weight: 200;
}

div#PubliForm div.infoPost div.texto p {
  margin: 7px 7px 20px 7px;
}

div.postTime {
  font-size: 13pt;
  font-weight: 200;
}

div.tagQr {
  position: absolute;
  right: 40px;
  bottom: 80px;
}

div.tagQr img {
  border-radius: 0 0 5px 5px;
}

div.tagQr .labelQr {
  background: linear-gradient(-45deg, #9e148c, #ff1e62, #8a31ff, #116955), radial-gradient(circle at top left, #9e148c, #ff1e62, #8a31ff, #116955);
  background-size: 400% 400%;
  background-clip: content-box, border-box;
  animation: gradient 3s ease infinite;
  border-radius: 5px 5px 0 0;
}

div.infoPost div.tagQr canvas {
  background-color: white;
  padding: 10px;
  border-radius: 0 0 5px 5px;
}

div.tagQr .labelQr p {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .05em;
  color: #fff;
  font-size: 11px;
  line-height: 1.3em;
  padding: 8px 0;
  margin: 0px;
}

div.NextPhotoName {
  position: absolute;
  top: 0;
  right: 0;
  margin: 65px 30px;
  padding: 0 0 0 10px;
  border-radius: 10px;
}

div.NextPhotoName div {
  display: flex;
  align-items: center;
  color: #ffffffbb;
  font-style: italic;
  font-weight: 300;
  letter-spacing: .03em;
  font-size: 1em;
}

div.NextPhotoName div img {
  width: 25px;
  height: 20px;
  opacity: .8;
}

div.NextPhotoName span {
  color: #57d8ff;
  font-weight: 600;
}

div.timerBar {
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
  display: flex;
  margin: auto;
  padding: 40px;
  opacity: .8;
}

div.baseProgress {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  box-shadow: 0 0 40px #1c132d;
  background-color: #ffffff80;
  overflow: hidden;
  margin: 0 5px;
}

div.progressBar {
  background-color: #57ff84;
  width: 0%;
  height: 100%;
}

div.filledProgressBar {
  background-color: #57ff84;
  width: 100%;
  height: 100%;
}

.animaBar {
  animation: progress linear;
}

@keyframes progress {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

div.assVertical {
  width: 70px;
  text-align: center;
  color: #ae9df8;
  margin: 15px auto;
  font-size: 9pt;
}

div.assVertical img {
  margin-top: 5px;
}

div.loadStart {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: #201b36;
  z-index: 1000;
  display: flex;
  align-items: center;
}

div.loadStart .container {
  margin: auto;
}

div.loadStart .container .assinatura {
  font-size: 20pt;
  margin-bottom: 20px;
}

div.AssinaturaEvento {
  color: #fff;
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 1em;
  letter-spacing: .18em;
  margin-top: 65px;
  font-weight: 700;
  background-image: linear-gradient(225deg,
      #e091ff 0%,
      #98b3ff 20%,
      rgb(228, 195, 255) 50%,
      #c2b1ff 80%,
      #fff 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
  display: inline-block;
  text-transform: uppercase;
}

div.AssinaturaEvento span {
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


div.loadStart .loaderSpan {
  text-align: center;
}

div.loadStart span.loader {
  margin: auto;
  width: 40px;
  height: 40px;
}

div.Home div.loadHome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main.envioScreen {
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

div.send {
  margin: 0 auto;
  text-align: center;
  display: flow-root;
  min-height: 100vh;
}

div.sendBox,
div.authBox {
  background-color: #fbfbff;
  width: 250px;
  margin: 10px auto 0 auto;
  padding: 30px;
  border-radius: 10px;
}

div.sendBox {
  margin-bottom: 20px;
}

span.nomeEventoOpen {
  letter-spacing: .3em;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14pt;
  color: #ffffffda;
  font-weight: 600;
}

div.titleSend .nomeEventoEnvio {
  text-transform: uppercase;
  letter-spacing: .3em;
  margin-bottom: 5px;
  padding: 0 10px;
  color: #a6a3b3;
  font-size: 8pt;
}

div.titleSend h1 {
  color: #7b56ab;
  margin-top: 0;
  font-size: 18pt;
  border-bottom: 1px solid #eee9f5;
  padding-bottom: 15px;
  font-weight: 900;
  font-family: 'Work Sans', sans-serif;
}

div.sendBox label {
  display: block;
  font-weight: bold;
  font-size: 8pt;
  color: #8a31ff;
  margin: 10px 0 3px 0;
  padding-left: 20px;
  text-align: left;
}

div.sendBox label span {
  font-weight: normal;
  font-size: 8pt;
  margin-left: 5px;
  color: #ae9dc5;
}

div.sendBox input,
div.authBox input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #8b8b8b;
  height: 25px;
  text-align: left;
  margin: 5px;
  color: #505050;
  width: 80%;
  font-family: 'Work Sans';
}

div.sendBox input:focus,
div.authBox input {
  border: 0;
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid #8a31ff;
}

div.AvatarSort {
  display: flex;
  flex-flow: wrap;
  gap: 7px;
  padding: 0 35px;
  margin: 10px 0;
}

div.AvatarSort .avatarPicker {
  flex: 1;
}

div.AvatarSort .avatarPicker img {
  width: 50px;
  border-radius: 5px;
  opacity: .6;
  cursor: pointer;
}

div.AvatarSort .avatarPicker img.avatarSelected {
  opacity: 1;
  box-shadow: 0px 0px 0px 3px #ff246d;

}

div.avatarEnvio label.envio {
  width: 70%;
  padding: 10px 10px;
  background: linear-gradient(-45deg, #8a31ff, #41b1d3);
  margin: 8px auto 20px auto;
  font-size: 10pt;
  text-align: center;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

div.avatarEnvio .perfilEnvio,
div.fotoENome .perfilEnvio {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px auto;
  text-align: center;
  position: relative;
}

div.fotoENome {
  display: inline-flex;
  align-items: center;
}

div.fotoENome .perfilEnvio {
  margin: 5px 0;
}

div.fotoENome h4 {
  text-align: left;
  margin: 0 auto;
  width: min-content;
  padding: 5px 10px;
  line-height: 1em;
  color: #19235c;
  font-size: 11pt;
}

div.fotoENome h4.step1 {
  text-align: center;
  width: auto;
}

div.sendBox button.to2Step {
  width: auto;
  padding: 10px 20px;
  background-color: #7b56ab;
  border: none;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

div.sendBox button.to2Step svg {
  width: 20px;
  margin-left: 5px;
}

div.avatarEnvio img.fotoDePerfil,
div.fotoENome .fotoDePerfil {
  object-fit: cover;
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.sendBox div.fotoPrincipalEnvio {
  width: 70%;
  margin: 10px auto;
  position: relative;
}

div.sendBox div.fotoPrincipalEnvio img {
  max-width: 100%;
  border-radius: 5px;
}

div.sendBox textarea.textLegenda {
  min-width: 200px;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #8b8b8b;
  padding: 5px 10px;
  font-family: 'Work Sans';
  font-weight: 200;
  font-size: 9pt;
  margin: 5px 0;
  height: 30px;
}

div.sendBox textarea.textLegenda:focus {
  border: 0;
  box-shadow: none;
  outline: none;
  height: 45px;
  border-bottom: 1px solid #8a31ff;
}

div.envioFoto {
  margin: 12px 0 0 0;
}

div.fotoInstrucao {
  display: inline-flex;
  margin-top: 20px;
}

div.fotoInstrucao p {
  text-align: left;
  font-size: 10pt;
  width: 70px;
  margin-left: 15px;
  color: #927b92;
}

.vertical {
  font-weight: bold;
  color: #8a31ff;
}

div.envioFoto img {
  width: 65px;
  opacity: .6;
}

div.envioFoto label {
  background: linear-gradient(-45deg, #9e148c, #ff1e62, #8a31ff, #116955), radial-gradient(circle at top left, #9e148c, #ff1e62, #8a31ff, #116955);
  background-size: 400% 400%;
  background-clip: border-box;
  animation: gradient 6s ease infinite;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #fff;
  font-size: 14pt;
  letter-spacing: .07em;
  font-family: 'Work Sans', sans-serif;
  width: -webkit-fill-available;
  border-radius: 50px;
  cursor: pointer;
}

label.temFoto {
  background: #309134 !important;
  content: 'okokok';
}

div.botaoSend button {
  padding: 10px 0;
  width: 80%;
  margin: 15px 0 10px 0;
  background-color: #8a31ff;
  border: none;
  font-size: 12pt;
  color: #fff;
  cursor: pointer;
}

div.botaoSend button:hover {
  background-color: #c440bd;
}

div.disclaimer {
  padding: 10px 5px 0 5px;
  margin: auto;
  gap: 5px;
  display: inline-flex;
  align-items: center;
}

div.disclaimer input {
  width: 50px;
  margin: 0;
}

div.disclaimer p {
  color: #645e64;
  font-size: 9pt;
  text-align: left;
  width: 160px;
}

div.disclaimer p button {
  background: none;
  color: #0775e7;
  padding: 0;
  width: auto;
  margin: 0;
  font-size: 10pt;
  text-align: left;
  font-weight: bold;
}

div.disclaimer p button:hover {
  background: none;
  text-decoration: underline;
}

div.sendBox p.envioInativo {
  margin: 0;
  color: #270d63;
}

div.sendBox svg.envioInativo {
  width: 80px;
  color: #cccee0;
}

div.blur {
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.0);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(50px);
  height: 100vh;
  top: 0;
  position: absolute;
}

div.modalComecar {
  background: #290c6a;
  text-align: center;
  border-radius: 20px;
  padding: 10px 0;
  z-index: 999;
}

div.modalComecar .texto {
  color: #fff;
  font-family: 'Baloo 2';
  font-size: 20pt;
  line-height: 1.1em;
  padding: 5px 40px;
}

div.modalComecar .texto span.melhor {
  font-style: italic;
  color: #bf8eff;
}

div.modalComecar button.comecar {
  padding: 10px 60px;
  background-color: #bf8eff;
  color: #112025;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 15px 0 30px 0;
}

div.modalComecar img {
  width: 80%;
}

div.modal,
div.modalComecar {
  position: absolute;
  top: 20px;
  width: 85%;
  max-width: 300px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

div.modal .container {
  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  padding: 0 10px 20px 10px;
  font-size: 10pt;
  text-align: justify;
}

div.modal .header {
  display: flex;
  gap: 20px;
  font-size: 9pt;
  color: #dfdcfc;
}

div.modal .header input[type="radio"] {
  display: none;
}

div.divCloseModal {
  margin-left: auto;
}

button.closeModal {
  align-self: flex-end;
  text-align: right;
  background: none;
  padding: 0;
  border: none;
  color: #fff;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

button.closeModal svg {
  width: max-content;
}

div.modal .header {
  border-bottom: 1px solid #3008df;
  padding: 20px 30px 0 30px;
  border-radius: 10px 10px 0 0;
  background-color: #392caf;
}

div.modal .header div {
  width: min-content;
}

div.modal .header label {
  cursor: pointer;
}

div.modal .header input[type="radio"]:checked+label {
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  border-bottom: 2px solid #9571e9;
}

div.modal .container .content {
  padding: 10px 20px;
}

div.modal .container .content p,
div.modal .container .content ul {
  color: #454052;
  font-size: 8pt;
}

div.formMessage {
  background-color: rgb(247, 212, 212);
  width: 75%;
  font-size: 10pt;
  color: #a35151;
  margin: 20px auto 0 auto;
  border-radius: 4px;
  padding: 10px 5px;
  border: 1px dashed #ffa3a3;
}

div.thanks {
  color: #fff;
  margin-top: 30px;
  font-size: larger;
}

div.thanks h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 20pt;
}

div.thanks p {
  color: #ffe6f4;
  font-weight: 100;
  font-size: 14pt;
  margin: -10px auto 0 auto;
}

div.thanks img {
  width: 200px;
  border-radius: 2px;
  box-shadow: 0 0 20px #ffffff50;
}

div.thanks div.frame {
  margin-top: 20px;

}

div.thanks .downloadShare {
  margin: 10px 0;
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
}

div.thanks div.downloadPhoto a {
  max-width: 300px;
  height: 40px;
  background-color: #4c0fdb;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 10px auto;
  gap: 5px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50px;
  padding: 0px 25px;
  font-size: 11pt;
  font-family: 'Work Sans', sans-serif;
}

div.thanks div.canvasBox canvas {
  width: 170px;
  border-radius: 2px;
  box-shadow: 0 0 20px #ffffff50;
}

div.baixarQrAdmin div.canvasBox {
  display: none;
  background-color: white;
  padding: 50px;
}

div.baixarQrAdmin div.downloadQR {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
}

div.baixarQrAdmin div.downloadQR button {
  background-color: #7201f3;
  border-radius: 5px;
  font-size: 9pt;
  letter-spacing: .05em;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(190, 215, 253);
}

div.baixarQrAdmin div.downloadQR button:hover {
  background-color: #2501f3;
}

div.baixarQrAdmin div.downloadQR a {
  color: rgb(190, 215, 253);
  text-decoration: none;
}

div.thanks button.share {
  max-width: 300px;
  height: 40px;
  border: none;
  background-color: #e3dee6;
  color: #4212b1;
  display: flex;
  align-items: center;
  margin: 10px auto;
  gap: 5px;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 25px;
  font-size: 11pt;
}

div.thanks button.share svg,
div.thanks div.downloadPhoto a svg {
  width: 25px;
  margin-left: 5px;
}

div.thanks .storyPendente {
  padding: 0 20px;
  text-wrap: pretty;
  font-size: 10pt;
  color: #e0ddf0;
}

div.send .comment {
  display: inline-flex;
  margin-top: 20px;
}

div.send .comment textarea {
  width: 180px;
  height: 60px;
  resize: none;
  background-color: #00000000;
  border: 1px solid #4c0fdb;
  color: #d6c5ff;
  outline: 0;
}

div.send .comment textarea::placeholder {
  color: #9f79f8;
  padding: 12px 10px;
  align-self: center;
  text-wrap: balance;
}

div.send .comment button {
  border: 0;
  background-color: #4c0fdb;
  color: #fff;
}

div.authBox {
  display: inline-block;
  margin-top: 50px;
}

.painel .container {
  padding: 30px 40px 20px 40px;
  background-color: #eeebed;
}

.painel .headerPainel {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
}

.painel h1 {
  color: #555360;
  border-left: 3px solid #8a31ff;
  padding-left: 10px;
  font-size: 15pt;
  font-weight: 300;
  font-family: 'Baloo 2', cursive;
  margin: 0;
}

.painel .painelIni {
  margin-left: 20px;
  display: inline;
}

.painel .dadosIni {
  display: inline-flex;
  align-items: baseline;
}

.painel .dadosIni input[type="file"] {
  display: none;
}

.painel .dadosIni label {
  background-color: #ac6bff;
  font-weight: bold;
  border: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px;
  letter-spacing: .1em;
  padding: 7px 10px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 10pt;
}

.painel .dadosIni label span {
  font-weight: lighter;
  text-transform: none;
  letter-spacing: 0;
  color: #ffffff60;

}

.painel .headerPainel .telaInicial button {
  background-color: #8a31ff;
  font-weight: normal;
  border: 0;
  margin-right: 5px;
  letter-spacing: .1em;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 8pt;
  font-weight: bold;
  text-transform: uppercase;
}

.painel .dadosIni input {
  height: 25px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  padding: 0 5px;
  margin-top: 5px;
}

.painel .downloadDiv {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.painel .downloadDiv span {
  font-style: italic;
  font-size: 10pt;
  color: #19235c;
}

.painel div.countEvento {
  display: inline-flex;
  margin-left: 20px;
  font-size: 15pt;
  vertical-align: middle;
  background-color: #000;
  color: #ff5454;
  padding: 2px 6px;
  font-family: monospace;
  border-radius: 5px;
}

.painel .headerPainel .telaInicial button.telaInicialAtiva,
button.storiesAtivo {
  background: linear-gradient(-45deg, #05ff05, #51ff4b, #7fffdf, #3ddd72), radial-gradient(circle at top left, #8a31ff, #ff1e62, #8a31ff, #116955);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  color: #3b1b64;
  cursor: default;
}

.painel .headerPainel button:hover {
  background-color: #6229ac;
}

.painel div.itemEvento {
  display: flex;
  padding: 0 30px 20px 30px;
  margin: 20px 0 0 0;
  border-bottom: 1px dotted #a29ed4;
  justify-content: space-between;
}

.painel div.itemEvento .evento {
  font-weight: bold;
}

.painel div.itemEvento .itemEventoFlex {
  display: flex;
  gap: 20px;
}

.painel div.itemEvento .itemEventoFlex .frame img {
  max-height: 120px;
  background-color: #000;
}

.painel div.itemEvento .itemEventoFlex .frame input {
  display: none;
}

div.containerCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px;
}

div.limitePosts {
  font-size: 10pt;
  padding: 0 30px;
  color: #696969;
  font-style: italic;
}

div.cardPainel {
  min-width: 200px;
  width: 12%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
}

div#PubliForm div.cardPainel .infoCard {
  color: #fff;
}

div#PubliForm div.cardPainel .linearFade {
  height: 200px;
  background: linear-gradient(to bottom, transparent -20%, #000 105%);
}

div.publicados .cardPainel {
  width: 140px;
  height: 200px;
  min-width: 140px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  padding: 0px;
  margin: 0 15px 15px 0;
}

div.publicados .cardPainel .imgCard {
  height: 200px;
  width: 140px;
  border-radius: 5px 5px 0 0;
  background-color: #000;
}

div.cardPainel div.imgCard .legendaCard {
  position: absolute;
  z-index: 99;
  bottom: 0;
  max-width: 75%;
  border-radius: 4px;
  font-size: 9pt;
  margin: 10px;
  padding: 2px 5px;
  background-color: #ffffffe0;
}

div.publicados .cardPainel .idCard {
  margin: 5px;
  background-color: #2c2c2c80;
  color: #fff;
  font-size: 9pt;
  font-weight: 600;
}

div.publicados .cardPainel .controlePubli {
  position: absolute;
  z-index: 999;
  margin-left: 80px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

div.publicados .cardPainel .controlePubli button {
  width: 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  padding: 0px 0px 0px 0px;
  margin: 2px;
}

div.publicados .cardPainel .controlePubli button.ativaPubli {
  background: none;
}

div.publicados .cardPainel .controlePubli button.pausaPubli {
  background: none;
}

div.publicados .cardPainel .controlePubli button.excluiPubli {
  background: #e91b1b;
  border: 2px solid #910f0f;
  color: #ffffff;
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

div.publicados .itens {
  position: relative;
}

div.publicados .cardPainel .infoCard {
  bottom: 10px;
  margin: 0;
  position: sticky;
  display: inline;
}

div.publicados .cardPainel .infoCard a {
  color: #3be4fa;
  text-decoration: none;
}

div.publicados .cardPainel .infoCard .publiHorario {
  color: #a7a7a7;
}

div.publicados div.cardPainel .infoCard .avatarCard {
  display: block;
  align-self: flex-start;
  margin-left: 7px;
}

div.publicados div.cardPainel .infoCard .textoCardPubli {
  font-size: 8pt;
  margin: 0;
}

div.publicados div.cardPainel .infoCard .avatarCard img {
  width: 35px;
}

div.publicados div.cardPainel .infoCard .textoCard {
  margin-right: 7px;
  display: inline-block;
}

div.publicados div.cardPainel .infoCard .textoCard h5 {
  font-size: 11pt;
  margin-bottom: 0;
  color: #ffffff;
}

div.cardPainel .imgCard {
  height: 250px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}

span.lazy-load-image-background {
  display: block !important;
}

div.imgPost span.lazy-load-image-background {
  height: 90vh;
  overflow: hidden;
}

div.cardPainel .imgCard img {
  left: 50%;
  position: relative;
  max-height: 300px;
  max-width: 500px;
  transform: translateX(-50%);
}

div.cardPainel .infoCard {
  display: flex;
  margin: 10px 5px;
}

div.cardPainel .infoCard .avatarCard {
  align-self: end;
  overflow: hidden;
  display: flex;
  flex: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

div.cardPainel .infoCard .avatarCard img {
  min-height: 100%;
  object-fit: cover;
  min-width: 100%;
}

div.cardPainel .infoCard .textoCard {
  margin-left: 7px;
  align-self: center;
}

div.cardPainel .infoCard .textoCard h5 {
  margin: 0 0 2px 0;
}

div.cardPainel .infoCard .textoCard p,
div.cardPainel .infoCard .textoCard div {
  font-size: 9pt;
  margin: 0;
}

div.cardPainel .infoCard .textoCard div {
  font-style: italic;
  font-size: 8pt;
}

div.gerencia {
  margin-top: 15px;
  display: flex;
}

div.idCard {
  font-size: 8pt;
  color: #fff;
  background-color: #8a31ff;
  width: fit-content;
  padding: 3px 7px;
  font-weight: 100;
  position: absolute;
  border-radius: 3px;
  z-index: 999;
  margin: 10px;
  opacity: 1;
}

div.gerencia button {
  height: 30px;
  cursor: pointer;
  width: -webkit-fill-available;
  border-radius: 5px;
}

div.gerencia button.aprovar {
  font-weight: bold;
  border: 0;
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: .1em;
  color: #3b1b64;
  font-size: 8pt;
  background: linear-gradient(-45deg, #05ff05, #51ff4b, #7fffdf, #3ddd72), radial-gradient(circle at top left, #8a31ff, #ff1e62, #8a31ff, #116955);
  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
}

div.gerencia button.aprovado,
div.gerencia button.excluido {
  display: none;
}

div.gerencia button.aprovar:hover {
  background-color: #259e2b;
}

div.gerencia button.excluir {
  background-color: #e75d53;
  font-weight: bold;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 8pt;
}

div.gerencia button.excluir:hover {
  background-color: #e24242;
}

footer .footer {
  margin: 0;
  padding: 30px 40px;
  color: #eeebed;
}

footer .footer h6 {
  font-size: 14pt;
  font-weight: 400;
  margin: 10px 0;
}

footer .footer p {
  font-size: 10pt;
  color: #dbd7da;
  font-weight: 100;
  margin: 0;
}

footer .footer p a {
  color: #bd8cff;
  text-decoration: none;

}

div.instaTagSection {
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

input.tagInput {
  height: 35px;
  padding: 0 0 0 10px;
  font-style: italic;
  border-radius: 7px 0 0 7px;
  border: 0;
}

input.tagInput:focus {
  border: 0;
  box-shadow: 0;
  outline: 0;
}

button.tagBtn {
  background-image: url(../src/images/search.png) !important;
  background-position: center !important;
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  height: 35px;
  width: 40px;
  background: #fff;
  border-radius: 0 10px 10px 0;
  border: none;
  color: rgb(30, 41, 49);
  font-weight: bold;
  padding: 0 10px;
  cursor: pointer;
}

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid rgb(195, 212, 204);
  margin-left: 10px;
  border-bottom-color: #bd8cff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

button span.loader {
  width: 20px;
  height: 20px;
  border: 3px solid rgb(195, 212, 204);
  margin-left: 0;
  border-bottom-color: #bd8cff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

p.instaInstrucao {
  display: inline-flex;
  font-size: 10pt;
  font-style: italic;
  margin-left: 10px;
  color: #6b6b6b;
}

button:disabled {
  background-color: rgb(189, 189, 189) !important;
  cursor: default !important;
  color: rgb(255, 255, 255);
}

div.tarjaInsta {
  background: linear-gradient(-45deg, #8a31ff, #6c4bff, #392caf, #441a7c);
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

div.tarjaInsta div.loginInstagram {
  display: inline-flex;
  color: #d6befc;
  align-items: center;
  font-size: 11pt;
}

div.tarjaInsta div.loginInstagram span {
  color: #fff;
  font-weight: bold;
  margin: 0 5px;
}

div.tarjaInsta div.loginInstagram button {
  width: 35px;
  border: none;
  background: none;
}

div.tarjaInsta div.loginInstagram button svg {
  color: #cfb1ff;
  margin: 0;
}

div.tarjaInsta div.loginInstagram input {
  border-radius: 2px;
  border: none;
  height: 25px;
  padding: 0 5px;
  width: 150px;
  margin: 0 5px;
}

div.tarjaStories {
  background: linear-gradient(-45deg, #6f34bb, #3008df, #392caf, #441a7c);
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

div.btnsStories {
  display: flex;
  align-self: center;
}

div.tarjaInsta h6 {
  color: #fff;
  margin: 0 20px;
  font-size: 20pt;
  font-weight: 100;
}

div.tarjaInsta svg {
  vertical-align: middle;
  cursor: pointer;
}

div.tarjaStories div.duracaoQuantidade {
  font-size: 10pt;
  color: #d0c5e0;
  align-self: center;
  display: flex;
  text-transform: uppercase;
}

div.tarjaStories div.duracaoQuantidade div {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

div.tarjaStories div.duracaoQuantidade svg {
  width: 22px;
}

div.tarjaStories div.duracaoQuantidade input[type="number"] {
  height: 25px;
  border: none;
  background: #2f1650;
  color: rgb(185, 171, 235);
  width: 30px;
  margin: 0 10px 0 5px;
  padding: 0 5px;
  border-radius: 5px;
}

div.tarjaStories div.duracaoQuantidade input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  height: 22px;
  width: 22px;
  margin: 0;
}

div.tarjaStories div.duracaoQuantidade .checkmark {
  height: 22px;
  width: 22px;
  background-color: #2f1650;
  border-radius: 3px;
}

div.tarjaStories div.duracaoQuantidade input:checked~.checkmark {
  background-color: rgb(185, 171, 235);
}

div.tarjaStories div.duracaoQuantidade .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

div.tarjaStories div.duracaoQuantidade input:checked~.checkmark:after {
  display: block;
}

div.tarjaStories div.duracaoQuantidade .checkmark:after {
  width: 5px;
  margin: 3px 7px;
  height: 10px;
  border: solid #2f1650;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


div.tarjaStories div.duracaoQuantidade button {
  height: 25px;
  margin-left: 5px;
  border-radius: 8px;
  padding: 0 10px;
  color: #fff;
  border: none;
  background-color: #7455ff;
  cursor: pointer;
}

div.tarjaStories div.duracaoQuantidade button:hover {
  background-color: #57ddff;
  color: #112025;
}

div.inpBtn,
div.infoTarjaInsta {
  align-self: center;
  display: flex;
}

h2.titleInstaAdmin {
  margin: 0;
  font-size: 20pt;
  color: #ba90ff;
  font-family: 'Baloo 2', cursive;
  font-weight: initial;
}

h2.titleStoriesAdmin {
  margin: 0;
  font-size: 20pt;
  color: #d0c5e0;
  font-family: 'Baloo 2', cursive;
  font-weight: initial;
}

h2.titleStoriesAdmin .avatar {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  display: inline-flex;
  vertical-align: middle;
  border: double 2px transparent;
  background: #3b1b64;
  opacity: .8;
}

h2.titleStoriesAdmin .avatar img {
  width: 100%;
  height: 100%;
}

h2.titleStoriesAdmin span {
  font-weight: bold;
  margin: 0 15px;
  color: rgb(240, 236, 255);
}

button.switchTags {
  font-weight: bold;
  border: 0;
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: .1em;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  color: #3b1b64;
  font-size: 10pt;
  background: linear-gradient(-45deg, #05ff05, #51ff4b, #7fffdf, #3ddd72), radial-gradient(circle at top left, #8a31ff, #ff1e62, #8a31ff, #116955);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

button.storiesSource {
  background-color: #7f4bc5;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .2em;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  color: #d0c5e0;
  font-size: 11pt;
}

button.storiesAtivo {
  font-weight: bold;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .2em;
  padding: 15px 25px;
  border-radius: 10px;
  font-size: 11pt;
}

button.storiesSource:hover {
  background-color: #2f1650;
}

div.containerCardsTags {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
}

div.tagPainel {
  width: 10%;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin: 0 10px 10px 0;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(133, 133, 133, 0.1);
}

div.tagPainel .imgCard {
  height: 180px;
  overflow: hidden;
  border-radius: 5px;
}

div.tagPainel .imgCard img {
  left: 50%;
  position: relative;
  max-height: 110%;
  transform: translateX(-50%);
}

div.tagPainel .infoCard {
  display: flex;
  margin: 10px 0px 0px 0px;
}

div.tagPainel .infoCard .avatarCard {
  align-self: end;
  display: flex;
}

div.tagPainel .infoCard .avatarCard img {
  width: 30px;
  border-radius: 50px;
}

div.tagPainel .infoCard .textoCard {
  margin-left: 7px;
  overflow: hidden;
}

div.tagPainel .infoCard .textoCard h5 {
  margin: 0 0 2px 0;
  font-size: 8pt;
  color: rgb(43, 43, 43);
}

div.tagPainel .infoCard .textoCard p,
div.tagPainel .infoCard .textoCard div {
  font-size: 9pt;
  margin: 0;
  color: #535353;
}

div.tagPainel .infoCard .textoCard div {
  font-style: italic;
  font-size: 8pt;
}

div.insta div.gerencia {
  margin-top: 10px;
  display: flex;
}

div.idCard {
  font-size: 8pt;
  color: #fff;
  background-color: #8a31ff;
  width: fit-content;
  padding: 3px 7px;
  font-weight: 100;
  position: absolute;
  border-radius: 3px;
  z-index: 999;
  margin: 10px;
  opacity: 1;
}

div.insta div.gerencia button {
  height: 25px;
  cursor: pointer;
  width: -webkit-fill-available;
  border-radius: 5px;
}

div.hashtag {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

div.labelHashtag {
  background: linear-gradient(-45deg, #f0106d, #bd1153, #0b86b3, #6519c9);
  padding: 7px 30px;
  border-radius: 5px;
  font-size: 12pt;
  width: fit-content;
  margin: auto;
  margin-bottom: -7px;
  position: relative;
  text-transform: uppercase;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: .1em;
  color: #fff;
}

div.wordHashtag {
  width: 85%;
  background: #fff;
  border-radius: 10px;
  color: #312977;
  padding: 20px;
  font-weight: bold;
  font-family: 'Work Sans', sans-serif;
  letter-spacing: -.00em;
  margin: 0 auto;
  background: linear-gradient(-45deg, #ffffff95, #ffffff, #ffffff95, #ffffff);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
}

div.tagPage div.infoPost {
  position: absolute;
  bottom: 235px;
  margin-left: 35px;
  display: flex;
  align-items: flex-end;
}

.displayNone {
  display: none;
}

div.assinatura {
  margin-top: -50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

div.modalComecar div.assinatura {
  margin-top: 10px;
}

main.App div.assinatura {
  margin-top: 20px;
}

main.painel div.assinatura {
  padding: 40px;
  margin-top: -10px;
}

div.send div.assinatura {
  margin-top: 30px;
}

div.assinatura div.nome {
  font-family: 'Baloo 2', cursive;
  font-weight: 600;
  color: #e3dee6;
  letter-spacing: .12em;
}

div.assinatura span {
  font-weight: 400;
  color: #bf8eff;
}

div#PubliForm {
  padding: 0 30px;
}

div#PubliForm .containerCards {
  padding: 30px 0;
}

div#PubliForm div.formPubli {
  padding: 0 0 30px 0;
  border-bottom: 1px solid #d3d0d7;
}

div#PubliForm .titlePubli {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

div#PubliForm .titlePubli button {
  background: none;
  padding: 20px 0;
  border: none;
  cursor: pointer;
}

div#PubliForm .titlePubli div.latLine {
  width: max-content;
  width: 100%;
  margin-left: 20px;
  height: 1px;
  background-color: #d3d0d7;
}

div#PubliForm form {
  display: flex;
  align-items: self-end;
  flex-wrap: wrap;
  gap: 10px;
}

div#PubliForm label {
  font-size: 10pt;
}

div#PubliForm input {
  height: 25px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  padding: 0 5px;
  margin-top: 5px;
  width: 90%;
}

div#PubliForm input[type="file"] {
  border: none;
}

div#PubliForm .duracaoPubli {
  max-width: 60px;
  margin-right: 20px;
}

div#PubliForm input:focus {
  border: 1px solid #8e84b8;
  outline: 0;
}

div#PubliForm div.nomePubli,
div#PubliForm div.instagramPubli,
div#PubliForm div.urlPubli {
  max-width: 150px;
}

div#PubliForm div.textoPubli {
  min-width: 300px;
}

div#PubliForm div.enviaPubli {
  min-width: 170px;
}

div#PubliForm div.enviaPubli button {
  height: 30px;
  width: 100%;
  cursor: pointer;
}

div#PubliForm button.publicarPubli {
  background-color: #5c47b8;
  color: #eee9f5;
  margin-bottom: 5px;
  border-radius: 3px;
  border: 1px solid #927fe6;
}

div#PubliForm div.previaPubli {
  height: 550px;
  border-radius: 10px;
  margin-top: 20px;
  background: #0b0b17;
}

div#PubliForm div.previaPubli div.imgPost {
  height: 100%;
  width: 370px;
}

div#PubliForm div.previaPubli div.imgPost img {
  height: inherit;
}

div#PubliForm div.previaPubli div.imgPost div.linearFade {
  position: sticky;
  height: 100%;
  backdrop-filter: blur(2px);
  background: linear-gradient(to bottom, transparent -60%, #000 105%);
}

div#PubliForm div.previaPubli .basePost {
  height: 100%;
}

div#PubliForm div.previaPubli div.infoPost {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  max-width: 250px;
  text-align: center;
}

div#PubliForm div.previaPubli div.infoPost div.avatar {
  display: inline-flex;
}

div#PubliForm span.Opcional {
  font-size: 8pt;
  margin-left: 10px;
  color: #8e8ebb;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .05em;
}

/* Home */
div.Home {
  width: 100% !important;
  margin: 0 auto;
  position: relative;
}

div.Home .context {
  top: 0vh !important;
  opacity: 1;
  animation: fadein 600ms linear;
  gap: 40px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  position: relative;
  align-items: center;
  text-align: center;
  color: #e1dce4;
}

div.Home .context div.textHome {
  flex-basis: min-content;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

div.Home .context div.areaScreen {
  background-color: #0b0b17;
  min-height: 100vh;
  aspect-ratio: 9/16;
}

/**/
.initial-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: black;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.fullscreen-image {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 3s ease-in-out;
}

.second-image {
  opacity: 0;
  transition: opacity 8s ease;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: 'Baloo 2';
  transform: translate(-50%, -50%);
  color: #bf8eff;
  text-align: center;
  z-index: 1001;
}

.fade-in {
  opacity: 1 !important;
}

.fade-out {
  opacity: 0 !important;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.saiba-mais-section {
  /* margin: 10px 0; */
}

.saiba-mais-content p {
  color: #b5b5db;
  background-color: #26264d;
  font-size: .9rem !important;
  font-weight: 500;
  padding: 15px 20px;
  text-align: justify;
  text-wrap: balance;
  line-height: normal;
  border-radius: 5px;
}

.saiba-mais-button:hover {
  opacity: 0.8;
}

.OpenStories.Home {
  opacity: 0;
  animation: fadeIn 3s ease-in forwards;
}

.gradient-text {
  background: linear-gradient(90deg, white, #bf8eff, white);
  background-size: 300%;
  -webkit-background-clip: text;
  line-height: 1em !important;
  background-clip: text;
  color: transparent;
  animation: gradient-animation 800ms ease infinite;
  font-size: 5rem !important;
}

@keyframes gradient-animation {
  0% {
      background-position: 0%;
  }
  70% {
      background-position: 100%;
  }
  100% {
      background-position: 0%; 
  }
}
/**/

div.Home .context div.testScreen {}

div.Home .context h1 {
  font-size: 40px !important;
  text-align: right !important;
  margin: 0 !important;
  line-height: 1em;
}

div.Home .context .qrHome {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  align-self: end;
  width: fit-content;
}

div.Home p {
  font-family: 'Baloo 2';
  font-size: 15pt;
  margin: 0;
  text-wrap: pretty;
}


/* Open Stories */

div.OpenStories {
  /* width: 600px; */
  height: 100vh;
  aspect-ratio: 9/16;
  margin: 0 auto;
  position: relative;
}

div.OpenStories .assVertical {
  width: 70px;
}

div.OpenStories div.openAbout {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  font-family: 'Baloo 2', cursive;
  top: 50%;
  z-index: 999;
  width: 70%;
}

div.OpenStories div.openAbout h2 {
  font-weight: bold;
  font-size: 50pt;
  line-height: 1em;
  color: #bf8eff;
  margin: 0px;
}

div.OpenStories div.openAbout p {
  color: #dbd7da;
  font-size: 22pt;
  line-height: 1.4em;
}

div.OpenStories div.openAbout div.tagQr {
  position: initial;
  margin-top: 40px;
}

div.OpenStories div.openAbout div.tagQr img {
  border-radius: 5px;
}

div.OpenStories div.openAbout div.assinatura {
  margin-top: 30px;
  font-size: 15pt;
}

div.OpenStories .context {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 40vh;
}

div.OpenStories .context h1 {
  text-align: center;
  font-family: 'Baloo 2', cursive;
  color: #e3dee6;
  font-size: 60px;
  letter-spacing: .08em;
  text-shadow: 0px 0px 15px #ffffff60;
  margin-left: 10px;
}

div.OpenStories .context h1 span {
  font-weight: 400;
  color: #bf8eff;
}

div.OpenStories .area {
  background: linear-gradient(90deg, #270d63, #19235c, #270d63);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
  width: 100%;
  min-height: 100vh;
}

div.OpenStories .circles {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

div.OpenStories .circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(127, 15, 255, 0.3);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

div.OpenStories .circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


div.OpenStories .circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

div.OpenStories .circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

div.OpenStories .circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

div.OpenStories .circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

div.OpenStories .circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

div.OpenStories .circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

div.OpenStories .circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

div.OpenStories .circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

div.OpenStories .circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 100%;
  }
}

/* Publicidade */

div.publi {
  height: 100vh;
}

div.publi div.imgPost {
  height: 100vh;
  width: 600px;
  margin: auto;
  overflow: hidden;
}


div.publi div.imgPost img {
  height: 100vh;
  position: relative;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

div.publi div.imgPost div.linearFade {
  position: sticky;
  bottom: 0;
  height: 100vh;
  background: linear-gradient(to bottom, transparent 0%, #000 105%);
}

div.publi .basePost {
  height: 100%;
}

div.publi div.fadeinPubli {
  animation: fadeinPubli linear;
}

div.publi div.infoPost {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  max-width: 400px;
  text-align: center;
  bottom: 170px;
}

div.publi div.infoPost div.texts {
  margin: auto;
  text-align: center;
  max-width: 100%;
}

div.publi div.infoPost div.texts h2 {
  font-size: 25pt;
  font-family: 'Work Sans', sans-serif;
}

div.publi div.infoPost div.texts .instaArroba {
  margin-top: 10px;
  font-size: 13pt;
}

div.publi div.infoPost div.texts .instaArroba svg {
  padding-right: 9px;
  margin-top: -1px;
}

div.publi div.infoPost .texto {
  color: #dbd7da;
  font-size: 18pt;
  font-weight: lighter;
}

div.publi div.infoPost .tagQr {
  position: initial;
}

div.publi div.infoPost .tagQr img {
  border-radius: 5px;
}

div.publi div.infoPost div.avatar {
  display: inline-flex;
  height: 100%;
  background: #ffffff20;
}

div.publi div.infoPost div.avatar img {
  width: 150px;
  height: 150px;
}

/* HEARTS */

@keyframes heartfade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.heart {
  z-index: 999;
  animation: heartfade 10s linear;
  position: absolute;
}

.heart:before,
.heart:after {
  content: "";
  background-color: #ff3067;
  position: absolute;
  height: 30px;
  width: 45px;
  border-radius: 15px 0px 0px 15px;
}

.heart:before {
  transform: rotate(45deg);
}

.heart:after {
  left: 10.5px;
  transform: rotate(135deg);
}

/* DONE */

div.doneScreen .assinatura {
  text-align: center;
  margin-top: 50px;
}

div.doneBoard {
  width: 90%;
  max-width: 400px;
  background-color: #efedf3;
  border-radius: 10px;
  margin: 50px auto;
  font-family: 'Baloo 2';
}

div.doneBoard .container {
  padding: 50px 40px;
}

div.doneBoard .header {
  font-size: 25pt;
  text-align: center;
  line-height: 1.1em;
  margin-bottom: 35px;
  color: #7b56ab;
  margin-top: 0;
  font-weight: 900;
  font-family: 'Work Sans', sans-serif;
}

div.doneBoard table {
  box-shadow: 0px 5px 12px -12px rgb(0 0 0 / 29%);
  border-top: 2px solid #d9d1ff;
  width: 100%;
  box-sizing: border-box;
}

div.doneBoard table tr {
  background-color: #fff;
}

div.doneBoard table td {
  padding: 2px 15px;
  color: #373a64;
  font-weight: 700;
}

div.doneBoard table td:last-child {
  text-align: right;
  font-weight: 300;
}

div.doneBoard table button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 12pt;
  font-weight: bold;
  color: #0192f3;
  cursor: pointer;
}

div.doneBoard table button:hover {
  text-decoration: underline;
}

div.doneScreen .email {
  margin: 40px;
  text-align: center;
}

div.doneScreen .email svg {
  width: 25px;
  color: #e3dee650;
  vertical-align: middle;
  margin-right: 10px;
}

div.doneScreen .email a {
  text-decoration: none;
  color: #69a7e9;
  letter-spacing: .04em;
}

main.envioScreen div.aniBg,
div.doneScreen div.aniBg {
  position: absolute;
  top: 0;
  left: 50%;
  margin: auto;
  z-index: -20;
  transform: translateX(-50%);
}

main.envioScreen div.aniBg div.OpenStories .area::before {
  height: 100%;
}

main.envioScreen div.assinatura {
  position: inherit !important;
  transform: none !important;
}

div.doneBoard td.downloadZip {
  text-align: center !important;
  padding: 12px;
}

div.doneBoard *::before,
div.doneBoard *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.mosaicContainer {
  aspect-ratio: 9/16;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 700px) {

  .Home .context {
    flex-direction: column;
    margin-top: 50px !important;
  }

  .Home .context .textHome {
    width: 80% !important;
  }

  .Home .imgPost {
    width: auto !important;
  }

  .painel .container {
    padding: 10px;
  }

  .painel .headerPainel {
    margin-bottom: 15px;
  }

  .painel div.countEvento {
    margin: 5px;
  }

  div.tarjaStories {
    margin-bottom: 20px;
  }

  div.tarjaStories,
  .painel .dadosIni {
    display: block !important;
    position: relative;
  }

  h2.titleStoriesAdmin {
    font-size: 12pt !important;
    display: inline-grid;
    line-height: 1em;
  }

  h2.titleStoriesAdmin span {
    margin: 10px 0 !important;
    font-size: 18pt;
  }

  div.btnsStories {
    margin-top: 15px;
  }

  div.btnsStories button {
    border-radius: 6px;
    width: 100%;
  }

  div.baixarQrAdmin div.downloadQR {
    margin: 10px 0;
  }

  div.containerCards {
    padding: 0px;
    justify-content: space-between;
    margin-bottom: 20px;
    column-gap: inherit !important;
    row-gap: 20px;
  }

  div.cardPainel {
    min-width: 40%;
    padding: 15px;
  }

  div.limitePosts {
    padding: 0;
  }

  div.assinatura div.nome {
    text-align: center;
  }
}

@media screen and (min-height: 1800px) {

  div.infoPost div.texts,
  div.assinatura div.nome,
  div.NextPhotoName div,
  div.AssinaturaEvento,
  div.tagQr .labelQr p {
    font-size: 2em !important;
  }

  div.postTime {
    font-size: 1em !important;
  }

  div.basePost .legenda {
    bottom: 15% !important;
  }

  div.basePost .legenda p {
    font-size: 1.5em !important;
  }
}